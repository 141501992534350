import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
const i18n = new VueI18n({
  locale: process.env.VUE_APP_DEFAULT_LANGUAGE,
  fallbackLocale: process.env.VUE_APP_DEFAULT_LANGUAGE,
  messages: loadLocaleMessages(),
})

i18n.locales = [{
  locale: 'en',
  // eslint-disable-next-line global-require
  img: require('@/assets/images/flags/en.png'),
  name: 'English',
},
{
  locale: 'fa',
  // eslint-disable-next-line global-require
  img: require('@/assets/images/flags/fa.png'),
  name: 'Persian',
},
]

export default i18n
